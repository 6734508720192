import { useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const MENU_WIDTH = 250;
const INNER_MENU_WIDTH = 300;
const MENU_HEIGHT = 40;
const INNER_MENU_HEIGHT = 45;

interface Options {
  icon?: string;
  title: string;
  options?: Options[];
  onClick?: () => void;
}

interface MenuProps {
  title?: string;
  x: number;
  y: number;
  options: Options[];
  disabled?: boolean;
}

const setMenuPosition = (xPosition: number, yPosition: number, menuItems: number) => {
  const heightRatio = yPosition / window.innerHeight;
  const left = xPosition - (xPosition + MENU_WIDTH > window.innerWidth ? MENU_WIDTH : 0);
  if (heightRatio > 0.8) {
    return { top: `${yPosition - MENU_HEIGHT * menuItems - 30}px`, left: `${left}px` };
  }
  return {
    top: `${yPosition}px`,
    left: `${left}px`,
  };
};

const computeFolderDepth = (title: string) => {
  const folders = title.split('/');
  const depth = folders.length;
  const leftMargin = (depth - 1) * 0.8;
  const widthClass = `${leftMargin}rem`;
  return widthClass;
};

const InnerContextMenu = ({ x, y, options }: MenuProps) => {
  return (
    <div
      className={`absolute z-10 w-[300px] origin-top-right rounded-md border-2 border-light-border bg-container-dark text-xs font-normal text-marveri-white shadow-lg drop-shadow-[4px_4px_2px_rgba(0,0,0,0.05)] hover:bg-container-hover focus:outline-none
`}
      style={setMenuPosition(x, y, options.length)}
      data-testid="inner-context-menu"
    >
      {options.map((option, index) => {
        return (
          <div
            key={index}
            onClick={option.onClick}
            className={`m-auto flex h-[40px] cursor-pointer rounded-md border-b border-container-hover bg-container-dark text-sm font-normal text-marveri-white shadow-lg hover:bg-container-hover focus:outline-none`}
            data-testid="inner-context-item"
          >
            <span
              className={`flex w-full items-center`}
              style={{ marginLeft: computeFolderDepth(option.title) }}
            >
              {option.icon && (
                <img src={option.icon} className="mx-[10px] inline-block size-[16px]" />
              )}
              {option.title.split('/').pop()}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export const ContextMenu = ({ title, x, y, options, disabled }: MenuProps) => {
  const [showInnerMenu, setShowInnerMenu] = useState<number | null>(null);
  const timeoutRef = useRef<number | null>(null);

  const clearTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const handleMouseEnter = (index: number) => {
    timeoutRef.current = window.setTimeout(() => {
      setShowInnerMenu(index);
      clearTimer();
    }, 300);
  };

  const handleMouseLeave = () => {
    clearTimer();
  };

  const getPositionX = () => {
    const xInnerContext = x + INNER_MENU_WIDTH * 2;
    if (xInnerContext > window.innerWidth) return -INNER_MENU_WIDTH;
    return MENU_WIDTH - 4;
  };

  //This will need to be refactored eventually. Using brute force method which is currently working.
  const getPositionY = (index: number, totalOptions: number) => {
    const yDefault = MENU_HEIGHT;
    const yInnerContext = y + INNER_MENU_HEIGHT * totalOptions;
    const windowHeight = window.innerHeight;
    if (index === 0) {
      if (yInnerContext > windowHeight) {
        return -yDefault * totalOptions + INNER_MENU_HEIGHT + 25;
      }
      return yDefault - 8;
    } else if (index === 1) {
      if (yInnerContext > windowHeight) {
        return -yDefault * totalOptions + INNER_MENU_HEIGHT + 60;
      }
      return yDefault + 28;
    } else if (index === 2) {
      return yDefault + 30;
    }
    return yDefault;
  };

  return (
    <ErrorBoundary fallbackRender={() => <></>}>
      <div
        className={` absolute z-10 ${
          'w-[' + MENU_WIDTH + 'px]'
        } origin-top-right rounded-md border-2 border-light-border bg-container-dark text-[12px] font-normal text-marveri-white shadow-lg drop-shadow-[4px_4px_2px_rgba(0,0,0,0.05)] hover:bg-container-hover focus:outline-none `}
        style={setMenuPosition(x, y, options?.length || 1)}
        data-testid="main-context-menu"
      >
        {title && <div className="truncate border-b-2 border-light-border p-2">{title}</div>}
        {options &&
          options.map((option, index) => (
            <div
              key={index}
              className={`group/context-menu-item ${
                'min-h-[' + MENU_HEIGHT + 'px]'
              } ${disabled ? 'pointer-events-none' : ''} m-auto cursor-default justify-center rounded-md border-b border-container-hover bg-container-dark text-xs font-normal text-marveri-white shadow-lg hover:bg-container-hover focus:outline-none
`}
              data-testid="main-context-item"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <span className={`block py-2 text-sm`} onClick={option.onClick}>
                {option.icon && (
                  <img src={option.icon} className="mx-[10px] inline-block size-[16px]" />
                )}
                {option.title}
                <div className="">
                  {option.options && index === showInnerMenu && (
                    <InnerContextMenu
                      x={getPositionX()}
                      y={getPositionY(index, option.options.length)}
                      options={option.options}
                    />
                  )}
                </div>
              </span>
            </div>
          ))}
      </div>
    </ErrorBoundary>
  );
};
