import { Menu } from '@headlessui/react';

import DownloadIcon from '@/assets/images/download-icon.svg';
import EllipsisIcon from '@/assets/images/ellipsis.png';
import TagIcon from '@/assets/images/tag-icon.svg';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';

interface PdfViewerDropDownProps {
  onMissingSignatureClick: () => void;
  onDownloadClick: () => void;
  isMissingSignatureVisible: boolean | null | undefined;
  isUserCurrentEditor: boolean;
}

export const PdfViewerDropDown = ({
  onMissingSignatureClick,
  onDownloadClick,
  isMissingSignatureVisible,
  isUserCurrentEditor,
}: PdfViewerDropDownProps) => {
  return (
    <Menu as="div" className="relative text-[12px] font-normal" data-testid="pdf-viewer-dropdown">
      <Menu.Button>
        <MarveriIcon icon={EllipsisIcon} iconStyle="h-[20px] w-[20px]" iconType="other" />
      </Menu.Button>
      <Menu.Items className="absolute right-0 z-[60] rounded-[5px] border border-light-border">
        {!isMissingSignatureVisible && (
          <Menu.Item>
            <div className={`${!isUserCurrentEditor ? 'pointer-events-none' : ''}`}>
              <div
                className={`flex w-[220px] cursor-pointer gap-2 rounded-[5px] border-b border-light-border bg-[#202020] p-2 hover:bg-container-hover`}
                onClick={onMissingSignatureClick}
              >
                <img src={TagIcon} />
                <span>{`Add “Missing Signature(s)” tag`}</span>
              </div>
            </div>
          </Menu.Item>
        )}
        <Menu.Item>
          <div
            className="flex w-[220px] cursor-pointer gap-2 rounded-[5px] border-b border-light-border bg-[#202020] p-2 hover:bg-container-hover"
            onClick={onDownloadClick}
          >
            <img src={DownloadIcon} className="-translate-y-px" />
            <span>Download</span>
          </div>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};
