import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import InfoIcon from '@/assets/icons/information-icon.svg';
import PlusIcon from '@/assets/icons/plus-icon-n500.svg';
import ArrowIcon from '@/assets/icons/rounded-arrow-icon.svg';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useOverview } from '@/pages/overview/common/utils';
import { trpcReact } from '@/utils/trpc';

interface ToolPageCardProps {
  toolName: string;
  toolIcon: string;
  toolIdentifier: string;
  toolReadyQuote: string;
  toolTip: string;
  path: string;
  isToolAdded: boolean;
}

export const ToolPageCard = ({
  toolName,
  toolIcon,
  toolIdentifier,
  toolReadyQuote,
  toolTip,
  path,
  isToolAdded,
}: ToolPageCardProps) => {
  const { matter, toolProgress, getToolProcessing, isUserCurrentEditor } = useOverview();
  const [isInfoIconHovered, setIsInfoIconHovered] = useState(false);
  const [isToolCardHovered, setIsToolCardHovered] = useState(false);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const updateToolSelection = trpcReact.clientMatter.addTool.useMutation();

  const addTool = useCallback(() => {
    if (isToolAdded) return;
    setLoading(true);
    updateToolSelection.mutate(
      {
        clientNumber: matter.client.number,
        number: matter.number,
        tool: toolIdentifier,
        clientMatterId: matter.id,
      },
      {
        onSettled: () => {
          setLoading(false);
        },
      },
    );
  }, [
    isToolAdded,
    matter.client.number,
    matter.id,
    matter.number,
    toolIdentifier,
    updateToolSelection,
  ]);

  const toolProcessing = useMemo(() => {
    return getToolProcessing(toolIdentifier);
  }, [getToolProcessing, toolIdentifier]);

  const generalToolProgress = useMemo(
    () => toolProgress(toolProcessing.startTime, toolProcessing.finishTime, date),
    [date, toolProcessing.finishTime, toolProcessing.startTime, toolProgress],
  );

  const handleToolCardClick = () => {
    if (isToolAdded && toolProcessing.complete) {
      if (toolIdentifier === 'REDLINE') {
        navigate(`/${matter.client.number}/${matter.number}/dataroom`);
      } else {
        navigate(`/${matter.client.number}/${matter.number}/${path}`);
      }
    } else addTool();
  };

  return (
    <div
      className={`${(isToolAdded && toolProcessing.complete) || isUserCurrentEditor ? '' : 'pointer-events-none'} ${isToolAdded && toolProcessing.complete ? 'border border-N500 bg-N600 text-marveri-white' : 'border border-N600 bg-container-default text-N300'} ${generalToolProgress <= 0 && 'hover:bg-N700'} group relative flex h-[148px] min-w-[230px] max-w-[320px] cursor-pointer flex-col rounded-[8px] pb-[16px] pl-[20px] pr-[16px] pt-[20px]`}
      data-testid="tool-page-card"
      onClick={handleToolCardClick}
      onMouseOver={() => setIsToolCardHovered(true)}
      onMouseOut={() => setIsToolCardHovered(false)}
    >
      <div
        className={`${!toolProcessing.complete ? 'absolute left-0 top-0 z-0 h-full rounded-l-[8px]' : 'hidden'}`}
        style={{
          width: `${generalToolProgress}%`,
          backgroundColor: '#302F30',
        }}
      ></div>
      <div className="relative flex items-start gap-[8px]" data-testid="tool-card-name">
        {!isInfoIconHovered && <img src={toolIcon} className="translate-y-[2px]" />}
        <span
          className={`${isInfoIconHovered ? 'text-[11px]' : 'text-[16px]'} w-[99%] text-left font-medium`}
        >
          {isInfoIconHovered ? toolTip : toolName}
        </span>
      </div>
      <span className="pt-[6px] text-[14px] font-medium text-N400">
        {isToolAdded && !isInfoIconHovered && toolProcessing.complete && toolReadyQuote}
      </span>
      <div className={`absolute bottom-[10px] flex w-[87%] items-center justify-between`}>
        {isToolCardHovered ? (
          <img
            src={InfoIcon}
            className="cursor-pointer opacity-50 hover:opacity-100 "
            onMouseEnter={() => setIsInfoIconHovered(true)}
            onMouseOut={() => setIsInfoIconHovered(false)}
          />
        ) : (
          <div></div>
        )}
        {generalToolProgress <= 0 || toolProcessing.complete ? (
          <div
            className={`${isToolAdded ? 'border-N300 bg-N400 group-hover:border group-hover:bg-[#D9D9D9]' : 'bg-N700 group-hover:bg-[#424242]'} flex size-[32px] items-center justify-center rounded-[34px]`}
            data-testid="tool-card-add-or-remove-tool"
          >
            {isToolAdded && toolProcessing.complete ? (
              <img src={ArrowIcon} className="size-4 -rotate-45" />
            ) : loading ? (
              <LoadingSpinner />
            ) : (
              <img src={PlusIcon} className="group-hover:filter-hover-filter" />
            )}
          </div>
        ) : (
          <div className="size-[32px]"> </div>
        )}
      </div>
    </div>
  );
};
