import { useCallback, useEffect, useMemo, useState } from 'react';

import XIcon from '@/assets/icons/gray-x-icon.svg';
import WhiteFileIcon from '@/assets/icons/white-file-icon.svg';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { useOverview } from '@/pages/overview/common/utils';
import { RightsClause } from '@/pages/overview/financing-rights/common/utils';
import { FinanceRightSource } from '@/pages/overview/financing-rights/content/FinancingRightsContent';

interface FinancingRightsCardProps {
  offSetId: number;
  sourceId: string;
  sourceFile: string;
  sourceFileOriginalName: string;
  description: string;
  clausesRevised: RightsClause[];
  selectSourceFile: (fileName: string, highlight: number[]) => void;
  handleJumpToHighlight?: (highlights: number[]) => void;
  selectedClauseId: string;
  setSelectedClauseId: (val: string) => void;
  setIsDeleteDialogVisible: (val: boolean) => void;
  setSelectedSourceFile: (val: FinanceRightSource) => void;
  selectedSourceFile?: FinanceRightSource;
}

export const FinancingRightsCard = ({
  offSetId,
  sourceId,
  sourceFile,
  sourceFileOriginalName,
  description,
  clausesRevised,
  selectSourceFile,
  handleJumpToHighlight,
  selectedClauseId,
  setSelectedClauseId,
  setIsDeleteDialogVisible,
  setSelectedSourceFile,
  selectedSourceFile,
}: FinancingRightsCardProps) => {
  const [updatedClausesWithId, setUpdatedClausesWithId] = useState<RightsClause[]>([]);
  const { isUserCurrentEditor } = useOverview();

  useEffect(() => {
    const clausesRevisedWithId = clausesRevised.map((clause, index) => ({
      ...clause,
      id: `${Date.now()}-${index}-${offSetId}`,
    }));

    setUpdatedClausesWithId(clausesRevisedWithId);
  }, [clausesRevised, offSetId]);

  const handleClauseClick = (clauseLocation: string, clauseId: string) => {
    const parsedLocations = JSON.parse(clauseLocation);
    setSelectedClauseId(clauseId);
    setSelectedSourceFile({
      fileName: sourceFile,
      financeRightId: sourceId,
      isSelectedFocus: true,
    });
    if (handleJumpToHighlight) {
      handleJumpToHighlight(parsedLocations.locations);
    }
    selectSourceFile(sourceFileOriginalName, parsedLocations.locations);
  };

  const handleDeleteIconClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      setSelectedSourceFile({
        fileName: sourceFile,
        financeRightId: sourceId,
        isSelectedFocus: false,
      });
      setIsDeleteDialogVisible(true);
    },
    [setIsDeleteDialogVisible, setSelectedSourceFile, sourceFile, sourceId],
  );

  const handleSelectSource = () => {
    selectSourceFile(sourceFileOriginalName, []);
    setSelectedSourceFile({
      fileName: sourceFile,
      financeRightId: sourceId,
      isSelectedFocus: true,
    });
  };

  const checkIfSelectedMatchesSourceId = useMemo(
    () => selectedSourceFile?.financeRightId === sourceId && selectedSourceFile?.isSelectedFocus,
    [selectedSourceFile, sourceId],
  );

  return (
    <div
      className={`${checkIfSelectedMatchesSourceId && 'bg-[#292929]'} mb-[8px] flex flex-col gap-[14px] rounded-[8px] bg-[#1b1b1b] px-[24px] py-[20px] text-[14px] hover:bg-[#292929]`}
      data-testid="financing-rights-card"
    >
      <div className="relative flex items-center gap-[15px]" onClick={handleSelectSource}>
        <div
          className="flex cursor-pointer items-center gap-[9px]"
          data-testid="financing-rights-card-source"
        >
          <img src={WhiteFileIcon} />
          <span className="text-[16px]">{sourceFile}</span>
        </div>
        <MarveriIcon
          icon={XIcon}
          iconStyle="absolute right-0 top-0"
          iconType="other"
          onClick={handleDeleteIconClick}
          disabled={!isUserCurrentEditor}
        />
      </div>
      <div
        className="flex cursor-pointer items-center gap-[9px] text-[#CECECE]"
        onClick={handleSelectSource}
        data-testid="financing-rights-card-description"
      >
        <h1 className="w-[90%] cursor-pointer text-[#CECECE]">{description}</h1>
      </div>
      <h2 className="text-[12px] font-bold text-[#545454]">SELECTED CLAUSES</h2>
      <div
        className={`${checkIfSelectedMatchesSourceId ? 'flex-col gap-[24px]' : 'flex-row gap-[8px]'} flex cursor-default `}
      >
        {updatedClausesWithId.map((revisedClause, index) => {
          return (
            <div
              key={index}
              className={`group flex cursor-pointer flex-col gap-[8px]`}
              onClick={() => handleClauseClick(revisedClause.location, revisedClause.id)}
              data-testid={`financing-rights-card-revised-clause`}
            >
              <span
                className={`${
                  selectedClauseId === revisedClause.id ? 'bg-[#214E4C]' : 'bg-[#183C3A]'
                } w-fit rounded-[8px] px-[12px] py-[8px] text-[#00C7BE] group-hover:bg-[#214E4C]`}
              >
                {revisedClause.brief_description}
              </span>
              {checkIfSelectedMatchesSourceId && (
                <span
                  className={`${
                    selectedClauseId === revisedClause.id ? 'opacity-100' : 'opacity-50'
                  } w-[90%] italic text-marveri-white group-hover:opacity-100`}
                >{`"${revisedClause.quotation}"`}</span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
