import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { DropdownItems } from '@/pages/overview/common/utils';

interface SimpleDropdownProps {
  icon: string;
  options: DropdownItems[];
  disabled?: boolean;
}

export const SimpleDropdown = ({ icon, options, disabled }: SimpleDropdownProps) => {
  return (
    <Menu as="div" className={`${disabled ? 'pointer-events-none' : ''} relative`}>
      <Menu.Button>
        <MarveriIcon icon={icon} iconStyle="" iconType="other" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 rounded-[5px] border-2 border-light-border bg-container-dark">
          <div className="">
            {options &&
              options.map((option, index) => (
                <div key={option.title}>
                  <Menu.Item
                    as="div"
                    key={option.title}
                    className={`${index === 0 ? 'rounded-t-[5px]' : ''}  ${
                      index === options.length - 1 ? 'rounded-b-[5px]' : ''
                    } px-4 py-2 hover:bg-container-hover`}
                  >
                    <span
                      className={'flex w-[160px] cursor-pointer gap-2'}
                      onClick={option.onClick}
                    >
                      {icon && <img src={option.icon} className="" />}
                      {option.title}
                    </span>
                  </Menu.Item>
                </div>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
